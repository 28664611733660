#DesaKami {
    width: 100%;
    height: 650px;
}
.DesaKami-container {
    width: 100%;
    height: 500px;
    display: flex;
    flex-flow: row wrap;
}

.vidyt {
    // border-radius: 20px;
}

.headtext {
    font-size: 25px;
    font-weight: 700;
}

.content-text, .content {
    display: flex;
    justify-content: center;
    width: 100%;
}

.content-text {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    .headline-content, .text-content {
        width: 100%;
        display: flex;
        justify-content: center;
        text-align: center;
    }
}

.marker-desakami {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 15px;
}

.content {
    position: relative;
}


.play-button {
    width: 250px;
    position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  cursor: pointer;
}



@media (max-width: 768px) {
    #DesaKami {
        // height: 700px;
    }
    // .vidyt {
    //     width: 360;
    //     height: 115;
    // }

    .video-player {
        width: 90%;
        border-radius: 0;
    }
   
    .play-button {
        width: 160px;
    }
}

@media (max-width: 576px) {
    .video-player {
        height: 250px;
    }
    .play-button {
        top: 45%;
        width: 140px;
    }
}