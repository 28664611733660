#article {
    width: 100%;
    height: 850px;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
}

.article-container {
    max-width: 100%;
}

.first-line, .second-line {
    height: 370px;
    display: flex;
    flex-wrap: wrap;
}

.left, .right {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.mark {
    background-color: #ffffff;
}
.marker {
    width: 30px;
    padding-bottom: 25px;
    
}

.ular {
    width: 600px;
}

.articleborder {
    padding: 10px;
    width: 500px;
    height: 200px;
}

.articlehead {
    font-weight: 600;
    font-size: 20px;
    padding-bottom: 5px;
    p {
        color: #000000;
    }
    
}

.articletxt {
    font-size: 15px;
}

.date {
    font-size: 10px;
    color: grey;
}

.next-article {
    width: 90%;
    height: 20px;
    display: flex;
    justify-content: end;
    align-items: baseline;
    p {
        color: #f85c2c;
        font-weight: 400;
    }
    img {
        padding-left: 4px;
        width: 10px;
        height: 13px;
    }
}

@media (max-width: 768px) {
    #article {
        height: 1250px;
    }

    .first-line, .second-line {
        height: 580px;
        flex-direction: column;
        display: flex;
        flex-flow: row wrap;

        .left, .right {
            width: 100%;
            height: 300px;
            img {
                max-width: 450px;
            }
        }

        .left {
            
            order: -1;
        }
    }

}