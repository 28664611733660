

// Carousel 

.carousel.slide {
    .carousel-control-prev-icon, .carousel-control-next-icon {
        display: none;
    }


    .carousel-indicators {
        margin-bottom: 10vh;
        li{
          
            background-color: transparent;
            margin: 3.5px;
            border: 2.1px solid #F1582E;
            color: transparent;
            opacity: 1;
            border-radius: 100%;
            width: 10.5px  !important;
            height: 10.5px !important;
        }
        
        li.active{
            width: 35px !important;
            background-color: #F1582E;
            border-radius: 50px;
        }
        
    }

    .carousel-inner {
        z-index: inherit;
        .carousel-item:first-child {
            .image-slider {
                background-position: 0%;
            }
        }
    }
    .carousel-item {
        width: 100%;
       
    .image-slider{
        height: 100vh;
        position: relative;
        width: 100vw;
        background-size: cover;
        // background-position: center;
        background-repeat: no-repeat;
        // filter: brightness(0.9)!important;
       
        .carousel-caption {
          
            position: absolute;
            left: 100px;
            bottom: 25%;
            text-align: left;

            h3 {

                font-size: 2vw;
            }
    
            p{
                font-size: 2vw;
                margin-top: 20px;
            }
        }
    
        .hero-image {
            position: absolute;
            
        }

        .hero-image.slide-1 {
            bottom: -120px;
            left: -25px;
            width: 100vw !important;
            margin: auto;
            text-align: center;
           filter: brightness(70%)
        } 

        .hero-image.slide-2 {
            bottom: -70px;
            right: 0px;
            width: 70vmax !important;
            filter: brightness(70%)
        } 
        .hero-image.slide-3 {
            bottom: -70px;
            right: 0px;
            width: 55vmax !important;
            filter: brightness(90%)
        } 
    }

    .carousel-item:first-child {
        
    }
    
    
    }
}

@media (max-width: 1500px )  {
    .hero-image {
        right: -10px;
        width: 660px !important;
    }

    .carousel-caption {
        h3 {

            font-size: 1.5rem;
        }
        p{
            font-size: 1.4rem;
        }
    
    }
}


@media (max-width: 992px )  {
    .image-slider{
        .hero-image {
        bottom: -30px!important;
    }

    .carousel-caption {
        position: absolute;
        top: 15%;
        left: 50px;
        // padding-inline: 4px!important;
        // position: absolute;
        // position: static!important;
        text-align: center!important;
        // margin: auto!important;
        h3 {
            // margin-top: 20vh!important;
            font-size:2rem;
            line-height: 50px;
        }
      
        p{
            font-size: 1.5rem;
        }
    }
    
}
}

@media (max-width: 767px )  {

    .carousel-inner {
        .carousel-item:first-child {
            .image-slider {
                background-position: center;
            }
        }
    }
  
    .carousel-caption {
        display: block!important;
        width: 100%;
        // position: static!important;
        top: 1%!important;
        left: 0%!important;
        h3 {
            margin-top: 20vh;
            font-size:1.4rem!important;

        }

        p{
            font-size: 1.3rem!important;
        }
    }
}





